import 'css-vars-ponyfill';
import 'intersection-observer';
import ReactGA4 from 'react-ga4';
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';
import '@drdropin-tech/theseus/lib/tailwind.css';
import './src/styles/tailwind.css';
import posthog from 'posthog-js';

// These css files needs to stay in this order
import './src/static/fonts.css';
import './src/static/base.css';

const gtmId = process.env.GATSBY_GOOGLE_TAGMANAGER_ID;
const ga4Id = process.env.GATSBY_GOOGLE_TRACKING_ID_GA4;

if (
  Cookies.get('gatsby-gdpr-google-analytics') === 'true' &&
  process.env.GATSBY_ENV === 'production'
) {
  if (ga4Id) {
    ReactGA4.initialize(ga4Id);
  }
  if (gtmId) {
    TagManager.initialize({
      gtmId,
    });
  }
}

if (
  Cookies.get('gatsby-gdpr-posthog') === 'true' &&
  process.env.GATSBY_ENABLE_POSTHOG
) {
  posthog.init(process.env.GATSBY_POSTHOG_APP_ID, {
    api_host: 'https://eu.posthog.com',
    autocapture: true,
    capture_pageview: true,
  });
}

// eslint-disable-next-line
export { default as wrapPageElement } from './src/providers/wrapPageElement';
